@charset "UTF-8";
/* CSS Document */
@font-face {
  font-family: AvenirLight;
  src: url("./assets/AvenirLTProLight.otf") format('opentype');
}
@font-face {
  font-family: AvenirRegular;
  src: url("./assets/AvenirLTProBook.otf") format('opentype');
}
@font-face {
  font-family: AvenirMedium;
  src: url("./assets/AvenirLTProMedium.otf") format('opentype');
}
@font-face {
  font-family: AvenirBold;
  src: url("./assets/AvenirLTProHeavy.otf") format('opentype');
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #2A2A2A;
  margin: 0;
}

@supports (content: "x" / "y") {
  a[href^="https://"]:after {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBmaWxsPSIjMDAwMDAwIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTAwIDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMkEyQTJBIiBzdHJva2Utd2lkdGg9IjExIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgeDE9Ijg2LjYzOSIgeTE9IjEzLjk5NiIgeDI9IjQ3Ljg5NyIgeTI9IjUzLjIwMyIvPjxwb2x5bGluZSBmaWxsPSJub25lIiBzdHJva2U9IiMyQTJBMkEiIHN0cm9rZS13aWR0aD0iMTEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBwb2ludHM9IiAgNjMuOTc4LDEyLjY3NCA4Ny4zNTksMTIuNjc0IDg3LjM1OSwzNS44OTkgIi8+PHBvbHlsaW5lIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzJBMkEyQSIgc3Ryb2tlLXdpZHRoPSIxMSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHBvaW50cz0iICA0OC4zNjcsMjMuMDQgMTYuMjQyLDIzLjA0IDE2LjI0Miw4NC44ODEgNzguMDgzLDg0Ljg4MSA3OC4wODIsNTIuNzU1ICIvPjwvc3ZnPg==");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    fill: #2A2A2A;
    height: 1em;
    margin-left: 0.25em;
    width: 1em;
    vertical-align: text-top;
    content: "" / "Opens in new Tab";
  }
  a[title="privacy policy"]:after, a[title="accessibility"]:after, a[title="learn more"]:after {
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTAwIDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjYmFjMmM2IiBzdHJva2Utd2lkdGg9IjExIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgeDE9Ijg2LjYzOSIgeTE9IjEzLjk5NiIgeDI9IjQ3Ljg5NyIgeTI9IjUzLjIwMyIvPjxwb2x5bGluZSBmaWxsPSJub25lIiBzdHJva2U9IiNiYWMyYzYiIHN0cm9rZS13aWR0aD0iMTEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBwb2ludHM9IiAgNjMuOTc4LDEyLjY3NCA4Ny4zNTksMTIuNjc0IDg3LjM1OSwzNS44OTkgIi8+PHBvbHlsaW5lIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2JhYzJjNiIgc3Ryb2tlLXdpZHRoPSIxMSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHBvaW50cz0iICA0OC4zNjcsMjMuMDQgMTYuMjQyLDIzLjA0IDE2LjI0Miw4NC44ODEgNzguMDgzLDg0Ljg4MSA3OC4wODIsNTIuNzU1ICIvPjwvc3ZnPg==");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    fill: #bac2c6;
    height: 1em;
    margin-left: 0.25em;
    width: 1em;
    vertical-align: text-top;
    content: "" / "Opens in new Tab";
  }
}

@supports not (content: "x" / "y") {
  a[href^="https://"]:after {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBmaWxsPSIjMDAwMDAwIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTAwIDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMkEyQTJBIiBzdHJva2Utd2lkdGg9IjExIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgeDE9Ijg2LjYzOSIgeTE9IjEzLjk5NiIgeDI9IjQ3Ljg5NyIgeTI9IjUzLjIwMyIvPjxwb2x5bGluZSBmaWxsPSJub25lIiBzdHJva2U9IiMyQTJBMkEiIHN0cm9rZS13aWR0aD0iMTEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBwb2ludHM9IiAgNjMuOTc4LDEyLjY3NCA4Ny4zNTksMTIuNjc0IDg3LjM1OSwzNS44OTkgIi8+PHBvbHlsaW5lIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzJBMkEyQSIgc3Ryb2tlLXdpZHRoPSIxMSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHBvaW50cz0iICA0OC4zNjcsMjMuMDQgMTYuMjQyLDIzLjA0IDE2LjI0Miw4NC44ODEgNzguMDgzLDg0Ljg4MSA3OC4wODIsNTIuNzU1ICIvPjwvc3ZnPg==");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    fill: #2A2A2A;
    height: 1em;
    margin-left: 0.25em;
    width: 1em;
    vertical-align: text-top;
    content: "";
    alt: "Opens in new Tab";
  }
  a[title="privacy policy"]:after, a[title="accessibility"]:after, a[title="learn more"]:after {
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTAwIDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjYmFjMmM2IiBzdHJva2Utd2lkdGg9IjExIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgeDE9Ijg2LjYzOSIgeTE9IjEzLjk5NiIgeDI9IjQ3Ljg5NyIgeTI9IjUzLjIwMyIvPjxwb2x5bGluZSBmaWxsPSJub25lIiBzdHJva2U9IiNiYWMyYzYiIHN0cm9rZS13aWR0aD0iMTEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBwb2ludHM9IiAgNjMuOTc4LDEyLjY3NCA4Ny4zNTksMTIuNjc0IDg3LjM1OSwzNS44OTkgIi8+PHBvbHlsaW5lIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2JhYzJjNiIgc3Ryb2tlLXdpZHRoPSIxMSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHBvaW50cz0iICA0OC4zNjcsMjMuMDQgMTYuMjQyLDIzLjA0IDE2LjI0Miw4NC44ODEgNzguMDgzLDg0Ljg4MSA3OC4wODIsNTIuNzU1ICIvPjwvc3ZnPg==");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    fill: #bac2c6;
    height: 1em;
    margin-left: 0.25em;
    width: 1em;
    vertical-align: text-top;
    content: "";
    alt: "Opens in new Tab";
  }
}


.header {
  background: #FFFFFF;
  height: 110px;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
  padding-top: 10px;
}
.container {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  padding: 0;
  vertical-align: middle;
}
.container-small {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  padding: 0;
  vertical-align: middle;
  display: none;
}
.row{
  height: 40px;
}
.logo-row {
  padding-bottom: 20px
}

.logo {
  height: 40px;
  float: left;
  display: flex;
}
.homednb {
  width: 180px;
  margin: auto 0;
}
.logo-header {
  content: url("./assets/logo-dnb.svg");
  width: 176px;
}
.logo-header:hover {
  cursor: pointer;
}
.logo-header:after {
  height: 40px;
}
.developer {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 40px;
  text-decoration: none;
  margin-left: 15px;
}
.div-btn-reg{
  float: right;
  height: 40px;
  margin: auto 0;
}
.btn-register {
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 40px;
  letter-spacing: 0.5px;
  color: #00607d; /*3095b4*/
  background-color: #FFFFFF;
  padding: 10px 24px 10px 24px;
  border-radius: 20px;
  border: 1px solid #005172;
  box-sizing: border-box;
}
.btn-register:hover {
  color: #005172;
  background-color: #FFFFFF;
  padding: 10px 24px 10px 24px;
}

.normal-filter {
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #00607d; /*3095b4*/
  background-color: #FFFFFF;
  padding: 10px 24px 10px 24px;
  border-radius: 20px;
  border: 1px solid #005172;
  box-sizing: border-box;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}

.normal-filter:hover {
  color: #005172;
  background-color: #FFFFFF;
  padding: 10px 24px 10px 24px;
}

.menu {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.menu ul {
  min-width: 50%;
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  justify-content: space-between;
}
.menu-link {
  text-decoration: none;
  color: #2A2A2A;
}
.menu-link:hover {
  text-decoration: underline;
}
.menu-burguer {
  max-width: 200px;
  position: relative;
  display: none;
}
.menu-burguer ul {
  display: none;
  margin-top:0;
  padding: 0;
  width: 200px;
  position: absolute;
  left: 1px;
  border-radius: 0 0 6px 6px;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
}
.menu-burguer input:checked ~ ul {
  display: block;
}
.menu-burguer input {
  display: none;
}
.menu-burguer label {
  display: inline-block;
  width: 100px;
  height: 37px;
  background-color: #FFFFFF;
  font-size: 14px;
  line-height: 38px;
  letter-spacing: 0.5px;
  color: #3095B4;
  text-align: center;
  user-select: none;
  border-radius: 20px;
  border: 1px solid #005172;
  box-sizing: border-box;
}
.menu-burguer input:checked ~ label{
  display: inline-block;
  width: 100px;
  height: 37px;
  background-color: #005172;
  opacity: 0.5;
  font-size: 14px;
  line-height: 38px;
  color: #FFFFFF;
  text-align: center;
  user-select: none;
  border-radius: 20px 20px 0 0;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
}
.menu-burguer li {
  display: block;
  background: #FFFFFF;
  margin: 0;
  padding: 10px;
  list-style: none;
  border-bottom: 1px solid #005172;
}
.menu-burguer li:last-child {
  border-radius: 0 0 6px 6px;
}
.dropdown {
  position: relative;
  cursor: pointer;
}
.dropdowncards {
  background: #FFFFFF;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  top: 100px;
  display: none;
}
.dropshow {
  display: block;
}
.container-cardmenu {
  display: flex;
  padding: 5px 0;
}
.dotborder {
  border-right: 1px dotted #B1B1B1;
}
.dotborder a {
  text-decoration: none;
}
.container-cardmenu .dotborder:last-child {
  border: none;
}
.cardmenu {
  width: 90px;
  margin: 15px 25px;
  text-align: center;
}
.cardmenu:hover {
  cursor: pointer;
}
.cardmenu:hover>h4 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #005172;
  text-decoration: underline;
}

.home {
  background: white;
  background-image: url("./assets/hd-dev.png");
  background-repeat: no-repeat;
  margin-top: 100px;
  height: 390px;
  background-size: cover;
}
.head {
  background: white;
  background-repeat: no-repeat;
  margin-top: 100px;
  height: 390px;
  background-size: cover;
}
.bg-all {
  background-image: url("./assets/hd-cases.png");
}
.bg-finance {
  background-image: url("./assets/hd-finance.png");
}
.bg-marketing {
  background-image: url("./assets/hd-marketing.png");
}
.bg-sales {
  background-image: url("./assets/hd-sales.png");
}
.bg-operations {
  background-image: url("./assets/hd-operations.png");
}
.bg-register {
  background-image: url("./assets/hd-register.png");
}
.container-title{
  padding-top: 70px;
}

h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 56px;
  line-height: 62px;
  letter-spacing: 0.3px;
  color: #FFFFFF;
  margin: 0;
  font-weight: 100;
}

.subt {
  width: 100%;
  margin-top: 12px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.3px;
  color: #FFFFFF;
}
.subt-b {
  width: 100%;
  margin-top: 12px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #FFFFFF;
}
.div-btn-home {
  margin-top: 50px;
}
.btn-register-b {
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  /*line-height: 50px;*/
  letter-spacing: 0.5px;
  color: #4d5254;
  background-color: #0AC1B9;
  padding: 12px 30px 10px;
  border-radius: 20px;
  border: 1px solid #4d5254;
  box-sizing: border-box;
  cursor:pointer;
}
.btn-register-b:hover {
  color: #005172;
  background-color: #FFFFFF;
}

.section-white {
  background: white;
  padding: 70px 0 70px 0;
}
.section-gray {
  background: #D9D9D9;
  padding: 70px 0 70px 0;
}

.section-blue {
  background: #2195B0;
  padding: 0px 0 0px 0;
}

.section-navy-blue {
  background: #003957;
  color: white;
  padding: 70px 0 70px 0;
}

.container-body {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  align-content: center;
}
.rack-triple {
  display: flex;
  height: auto;
  justify-content: space-between;
}
.rack-quad {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: center;
  padding: 0 20px;
}
.logoStyle {
  height: 70%;
  width: 70%;
}
.logoWidth {
  height: 60%;
  width: 60%;
}

.imgColumn {
  float: left;
  width: 14.28%;
  padding: 0px;
}

/* Clearfix (clear floats) */
.imgRow::after {
  content: "";
  clear: both;
  display: table;
}

.rack-list {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: center;
  padding: 0;
}
.card-list {
  background: #FAFBFB;
  display: flex;
  align-items: center;
  width: 950px;
  height: 270px;
  margin-bottom: 30px;
  border: 1px solid #CFD1D1
}
.subfilter {
  padding-left: 20px
}

.img-card-list {
  text-align: center;
  width: 250px;
  height: 200px;
  border-right: 1px solid #CCCCCC;
}
.img-size-list {
  width: 112px;
  margin: 25px 0;
}
.txt-list {
  font-family: Arial, Helvetica, sans-serif;
  color: #575757; /*#868686;*/
  font-size: 14px;
  line-height: 20px;
  max-width: 600px;
  padding: 0 50px;
  margin: auto 0;
}
.card-triple {
  background: #FFFFFF;
  width: 330px;
  height: 550px;
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
  text-align: left;
  position: relative;
  border: 1px solid #CFD1D1
}

.card-icon-triple {
  background: #FFFFFF;
  width: 330px;
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
  text-align: left;
  margin-bottom: 25px;
  border: 1px solid #CFD1D1
}
.card-quad {
  background: #FFFFFF;
  width: 330px;
  height: 600px;
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
  margin: 30px 20px;
  position: relative;
}
.card-quad:hover {
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
}
.card-quad-small {
  background: #FFFFFF;
  width: 330px;
  height: 330px;
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
  margin: 30px 20px;
  position: relative;
}
.img-size-quad-small {
  width: 100px;
  margin: 15px 0 15px;
}
.img-card-quad-small {
  text-align: center;
  width: 330px;
  height: 130px;
}
.card-title {
  margin: 0 30px;
}

.img-size-triple {
  width: 330px;
}
.img-size-quad {
  width: 180px;
  margin: 30px 0 10px;
}
.img-card {
  text-align: center;
  width: 330px;
  height: 330px;
}
.img-card-quad {
  text-align: center;
  width: 330px;
  height: 220px;
}
.icon-card {
  text-align: center;
  width: 330px;
}
.icon-size-triple {
  width: 150px;
  margin-top: 35px;
}
.txt-triple {
  font-family: Arial, Helvetica, sans-serif;
  color: #575757; /*868686*/
  font-size: 14px;
  line-height: 20px;
  padding: 0 30px;
  margin-bottom: 25px;
}
.readmore {
  font-family: Arial, Helvetica, sans-serif;
  width: fit-content;
  font-size: 12px;
  line-height: 14px;
  color: #005a78; /*#0d7594;*/
  margin: 15px 30px;
  text-decoration: none;
  border-bottom: 2px solid #C1E9F5;
  position: absolute;
  bottom: 25px;
}
.readmore:hover{
  color: #005172;
  border-bottom: 2px solid #005172;
  cursor: pointer;
}
.btn-center {
  text-align: center;
  margin-top: 70px;
}
h2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: 0.2px;
  color: #2A2A2A;
  margin: 0 0 16px;
}

h3 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 30px;
  color: #4D5254;
  font-weight: 100;
  text-align: left;
  letter-spacing: 0.3px;
}

h4 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 100;
  color: #4D5254;
  letter-spacing: 0.3px;
  margin: 10px 0;
}

h5, .smallh2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #2A2A2A;
  letter-spacing: 0.3px;
  padding-bottom: 25px;
  border-bottom: 1px solid #D6D6D6;
  margin-bottom: 50px;
}

p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #2A2A2A;
  padding: 0;
  margin: 0;
}
.intro {
  margin-bottom: 50px;
}

.apis-intro {
  display: flex;
  margin-bottom: 45px;
}
.apis-txt{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #2A2A2A;
  padding-right: 40px;
}
.apis-img {
  text-align: center;
  width: 400px;
}
.apis-img-size {
  width: 400px;
}

.space {
  margin-bottom: 30px;
}
strong {
  font-family: Arial, Helvetica, sans-serif;
}

.rack-double {
  display: flex;
  width: 70%;
  height: auto;
  justify-content: space-between;
  padding: 0 30px;
  margin: 0 auto;
}
.card-double {
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 25px 0;
  text-align: center;
}
.txt-double {
  height: 60px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 24px;
  padding: 0 15px;
}
.rack-regis-double {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;
  padding: 0 10px;
  margin: 0 auto;
}
.card-regis-double {
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  text-align: left;
}
.rack-register {
  display: flex;
}
.regis-left {
  flex: 70%;
}
.regis-right {
  flex: 30%;
}
.btn-regis {
  margin: 15px 0;
}
.docu {
  font-family: Arial, Helvetica, sans-serif;
  width: fit-content;
  font-size: 16px;
  line-height: 20px;
  color: #005a78; /*#0D7594;*/
  text-decoration: none;
  border-bottom: 2px solid #C1E9F5;
}
.docu:hover{
  color: #005172;
  border-bottom: 2px solid #005172;
  cursor: pointer;
}

footer {
  background-color: #2f3335;
  color: #f4f6f6;
}
footer *, footer a:visited {
  color: #bac2c6; /*#a2aaad;*/
}

footer * > ul {
  display: inline;
  list-style-type: none;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  justify-content: space-evenly;
}

footer * > li {
  display: inline;
  margin: 10px 0 0;
  padding-bottom: 0;
}

footer * > a {
  text-decoration: none;
}

footer * > a:hover {
  text-decoration: underline;
}

@media (max-width: 1180px){
  .rack-triple {
    padding: 0;
  }
  .card-triple {
    width: 320px;
    height: 480px;
  }
  .img-card {
    width: 320px;
    height: 320px;
  }
  .img-size-triple {
    width: 320px;
  }
}


/* MARKETO FORM Styling */
.mktoField {
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-bottom-color: #575757 ! important; /*#868686*/
  border-bottom-style: solid;
  border-bottom-width: 1px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px ! important;
  color: #999 ! important;
  background-color: transparent ! important;
  padding: .5em 0;
}

.mktoLabel {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px ! important;
  color: #535353; /*#868686;*/

}

.mktoFormRow {
  margin-left: -15px ! important;
  margin-right: -15px ! important;
  /*margin-bottom: 2% ! important;*/
}

.mktoButton {
  background-color: transparent ! important;
  border-color: #000 ! important;
  box-shadow: none ! important;
  color: #000 ! important;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  border-width: 1px ! important;
  border-style: solid;
  border-radius: 25px;
  padding: .5em 1.8em;
  font-size: 100%;
  letter-spacing: .06em;
  background-image: none ! important;
}
.mktoHtmlText > .alert {
  background-color: white !important;
}

@media (max-width: 1080px){
  .card-triple {
    width: 300px;
    height: 460px;
  }
  .img-card {
    width: 300px;
    height: 300px;
  }
  .img-size-triple {
    width: 300px;
  }
  .card-icon-triple {
    width: 300px;
  }
  .icon-card {
    width: 300px;
  }
  .icon-size-triple {
    width: 150px;
    margin-top: 35px;
  }
}
@media (max-width: 1024px){
  .container-title{
    max-width: 800px;
  }
  h1 {
    font-size: 46px;
    line-height: 52px;
  }
  .subt {
    font-size: 18px;
    line-height: 25px;
  }
  .card-triple {
    width: 280px;
    height: 450px;
  }

  .img-card {
    width: 280px;
    height: 280px;
  }
  .img-size-triple {
    width: 280px;
  }
  .card-title h3 {
    font-size: 18px;
  }
  .card-icon-triple {
    background: #FFFFFF;
    width: 280px;
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
    text-align: left;
  }
  .icon-card {
    text-align: center;
    width: 280px;
  }
  .icon-size-triple {
    width: 150px;
    margin-top: 35px;
  }
}

@media (max-width: 950px){
  .header {
    height: 50px;
  }
  .container {
    display: none;
  }
  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .menu {
    display: none;
  }
  .container-small {
    display: block;
  }
  .menu-burguer {
    display: block;
  }
  .home {
    margin-top: 60px;
  }
  .head {
    margin-top: 60px;
  }
  .card-list {
    height: 300px;
  }
  .img-card-list {
    width: 200px;
  }
  .txt-list {
    max-width: 550px;
    padding: 0 30px;
  }
  .card-triple {
    width: 260px;
    height: 430px;
  }
  .img-card {
    width: 260px;
    height: 260px;
  }
  .img-size-triple {
    width: 260px;
  }
  .card-icon-triple {
    background: #FFFFFF;
    width: 250px;
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
    text-align: left;
  }
  .icon-card {
    width: 250px;
  }
  .icon-size-triple {
    width: 120px;
    margin-top: 35px;
  }
}

@media (max-width: 880px){
  .container-title{
    max-width: 700px;
    padding-left: 50px;
    padding-top: 50px;
  }
  h1 {
    font-size: 40px;
    line-height: 50px;
  }
  .subt {
    font-size: 16px;
  }
  .card-list {
    height: auto;
  }
  .txt-list {
    max-width: 500px;
    padding: 0 20px;
    margin: 20px 0;
  }
  .card-triple {
    width: 240px;
  }
  .img-card {
    width: 240px;
    height: 240px;
  }
  .img-size-triple {
    width: 240px;
  }
}

@media (max-width: 768px){
  .container-title{
    max-width: 650px;
    padding-left: 40px;
    padding-top: 50px;
  }
  .home {
    margin-top: 60px;
  }
  .card-triple {
    width: 220px;
  }
  .card-title {
    font-size: 16px;
  }
  .img-card {
    width: 220px;
    height: 220px;
  }
  .img-card-list {
    width: 150px;
  }
  .img-size-list {
    width: 120px;
  }
  .card-triple {
    width: 260px;
    height: 430px;
  }
  .img-size-triple {
    width: 220px;
  }
  .icon-size-triple {
    width: 100px;
  }
  .txt-triple {
    font-size: 14px;
  }
  .card-icon-triple {
    width: 220px;
  }
  .icon-card {
    width: 220px;
  }
  .icon-size-triple {
    width: 120px;
    margin-top: 35px;
  }
  .txt-double {
    height: 100px;
    font-size: 14px;
  }
  .apis-intro {
    flex-direction: column;
  }
  .apis-txt {
    margin-bottom: 40px;
  }
  .apis-img {
    width: 100%;
  }
}

@media (max-width: 740px){
  .rack-triple{
    flex-wrap: wrap;
    padding: 0 90px;
  }
  .card-triple {
    margin-bottom: 30px;
  }
  .rack-double {
    width: 80%
  }
  .rack-regis-double {
    width: 80%
  }
  .txt-double {
    height: 80px;
    font-size: 14px;
  }
  .img-size-list {
    width: 100px;
  }
}

@media (max-width: 690px){
  .menu-burguer label {
    width: 60px;
    font-size: 10px;
    line-height: 30px;
    border-radius: 20px;
    height: 30px;
    margin-top: 5px;
  }
  .menu-burguer input:checked ~ label {
    width: 60px;
    font-size: 10px;
    line-height: 30px;
    border-radius: 12px 12px 0 0;
    height: 30px;
  }
  .developer {
    font-size: 15px;
    line-height: 40px;
  }
  .div-btn-reg {
    display: none;
  }
  .container-title {
    padding-top: 30px;
  }
  .head {
    height: 300px;
  }
  .rack-triple{
    padding: 0;
    justify-content: center;
  }
  .card-triple {
    width: 330px;
    height: 450px;
  }
  .img-card {
    width: 330px;
    height: 330px;
  }
  .img-size-triple {
    width: 330px;
  }
  .card-title h3 {
    font-size: 18px;
  }
  .card-icon-triple {
    width: 330px;
  }
  .icon-card {
    width: 330px;
  }
  .icon-size-triple {
    width: 150px;
  }
  .txt-triple {
    font-family: Arial, Helvetica, sans-serif;
  }
  .rack-double {
    flex-wrap: wrap;
    justify-content: center;
  }
  .rack-regis-double {
    flex-wrap: wrap;
    justify-content: center;
  }
  .card-double {
    width: 300px;
  }
  .card-regis-double {
    width: 300px;
  }
  .txt-double {
    height: 100px;
    font-size: 16px;
    padding: 0px;
  }
  .btn-center{
    margin-top: 25px;
  }
  .card-list {
    background: #FFFFFF;
    width: 330px;
    height: 550px;
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
    text-align: left;
  }
  .img-card-list {
    width: 330px;
    height: 200px;
    border-right: 0;
  }
  .img-size-list {
    width: 170px;
    margin: 20px 10px;
  }
  .txt-list {
    margin: 0;
  }
}

@media (max-width: 425px){
  .logo-header {
    content: url("./assets/isotype-dnb.svg");
    width: 20px;
  }
  .homednb {
    width: 25px;
  }
  .home {
    height: 600px;
  }
  .developer {
    font-size: 15px;
    line-height: 40px;
  }
  .container-title{
    max-width: 380px;
    padding-left: 20px;
    padding-top: 20px;
  }
  h1 {
    font-size: 40px;
    line-height: 48px;
  }
  .subt {
    font-size: 16px;
    line-height: 20px;
  }
  .apis-img-size {
    width: 100%;
  }
}

@media (max-width: 375px){
  .container-title{
    max-width: 320px;
    padding-left: 20px;
    padding-top: 20px;
  }

  .home {
    height: 630px;
  }
  h1 {
    font-size: 30px;
    line-height: 38px;
  }
  .subt-b {
    font-size: 14px;
  }
  .card-triple {
    width: 260px;
  }
  .img-card {
    width: 260px;
    height: 260px;
  }
  .img-size-triple {
    width: 260px;
  }
  .icon-size-triple {
    width: 120px;
  }
  .img-card-list {
    width: 260px;
    height: 160px;
    border-right: 0;
  }
  .img-size-list {
    width: 130px;
    margin: 20px 10px;
  }
}