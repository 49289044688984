@font-face {
    font-family: AvenirLight;
    src: url("./assets/AvenirLTProLight.otf") format('opentype');
}
@font-face {
    font-family: AvenirRegular;
    src: url("./assets/AvenirLTProBook.otf") format('opentype');
}
@font-face {
    font-family: AvenirMedium;
    src: url("./assets/AvenirLTProMedium.otf") format('opentype');
}
@font-face {
    font-family: AvenirBold;
    src: url("./assets/AvenirLTProHeavy.otf") format('opentype');
}

:root {
    --nav-size-height: 6vh;
    --nav-size-width: 20vh;
    --nav-height-open: 40vh;
    --nav-height-open-2: 20vh;
    --bg-menu: white;
    --bg-hover: #F4F6F6;
}

@keyframes item-fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; height: 40px; }
}

.menu-container {
    width: 100%;
    height: 40px;
    margin-top: 0px;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
}

nav {
    /*margin-top: 15%;*/
    background-color: var(--bg-menu);
    /*border-radius: 6px;*/
    color: #4D5254;
    display: flex;
    flex-flow: column nowrap;
    justify-content: stretch;
    transition: 120ms height linear, 120ms width linear, 120ms background-color linear;
}

.menu-container nav {
    margin-right: 20px;
}

.menu-container nav:nth-child(1) header, .menu-container nav:nth-child(4) header {
    min-width: 110px;
}

nav > header {
    min-width: 110px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: left;
    transition: 180ms font-size linear;
    cursor: default;
}

nav > header > b { font-size: 14px; }

nav > header > b:hover {
    text-decoration: underline;
}

nav:hover > header {
    transition: 180ms font-size linear;
    font-size: 1.2em;
}

nav > div {
    width: 110px;
    height: 0;
    opacity: 0;
    display: flex;
    justify-content: left;
    align-items: center;
    transition: 240ms background-color linear;
    font-size: 1em;
    cursor: default;
    padding-left: 25px;
    background-color: #F4F6F6;
}

nav:hover > div {
    width: 110px;
    animation-delay: 120ms;
    transition: 120ms background-color linear;
    animation: 240ms item-fade-in linear;
    animation-fill-mode: forwards;
}

nav:nth-child(2):hover > div:hover, nav:nth-child(3):hover > div:hover {
    background-color: var(--bg-menu);
    transition: 120ms background-color linear;
}
