.modal-container,
.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: none;
	background-color: rgba(245, 250, 252, 0.9);
	z-index: 3000;
	backdrop-filter: blur(8px);
	-webkit-backdrop-filter: blur(8px);
	overflow: auto;
	padding: 32px;

	&.open {
		display: flex;
	}

	.modal {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: #ffffff;
		min-width: 632px;
		height: min-content;
		border-radius: 4px;
		border: 1px solid #d7e0e4;
		padding: 56px;
		margin: auto;
		position: relative;
	}

	.type-container{
		padding: 56px 0;

	}
	.button-close{
		position: absolute;
		top: 12px;
		right: 12px;

		:hover{
			cursor: pointer;
		}
	}
}

.no-scroll-on-body{
	overflow: hidden;
}


#mdiv {
	width: 20px;
	height: 20px;
}

.mdiv {
	height: 20px;
	width: 2px;
	margin-left: 12px;
	background-color: black;
	transform: rotate(45deg);
	Z-index: 1;
}

.md {
	height: 20px;
	width: 2px;
	background-color: black;
	transform: rotate(90deg);
	Z-index: 2;
}